import { navigate } from "gatsby"
import React, { useEffect } from "react"
import InvestPage from "../invest"

interface Props {
  location: { state: { formCompleted: boolean } }
}

const InvestThanks = (props: Props) => {
  useEffect(() => {
    if (!props.location.state?.formCompleted) {
      navigate("/invest")
    }
  }, [props])

  return <InvestPage didSubmit />
}

/** export */
export default InvestThanks
